"use client"

import { useEffect, useRef, useState } from "react";
import { IFixedDownloadButtonProps, IFloatingDownloadButtonComponentProps, } from "../../../lib/types/components";
import {
    APK_DOWNLOAD_AGAIN_DELAY,
    FIXED_DOWNLOAD_BUTTON_TYPE,
    GET_APP_FACEBOOK_URL,
} from "../../../lib/utils/constants/GlobalConstants";
import {
    checkIsGetAppFb,
    isiOSDevice,
    isMobile,
    setIsHowToVideoAvailable,
} from "../../../lib/utils/constants/PagesConstants";
import FixedDownloadButtonV2 from "../FixedDownloadButton/FixedDownloadButtonV2";
import CustomImageComponent from "../CustomImage/CustomImageComponent";
import useIsIOS from "../../../lib/utils/helpers/useIsIOS";
import { DownloadButtonState, useDownloadContext } from "../../../lib/context/CommonContext";
import Analytics from "../../../lib/analytics/Analytics";
import { HowToVideoClose, HowToVideoOpen, HowToVideoPlayback, MuteHowToVideo } from "../../../lib/analytics/AnalyticsEvents";
import styles from "./FloatingDownloadButtonComponentWithVideo.module.css"
import { multipleClassName } from "../../../lib/utils/helpers/helper";

const FloatingDownloadButtonComponentWithVideo = (
    props: IFloatingDownloadButtonComponentProps
) => {
    const {
        fixedDownloadButtonData,
        blinkTextDownload,
        blinkTextDownload2,
        pageName,
        isMute = false
    } = props;

    const videoTag = useRef<HTMLVideoElement>(null)
    let isIos = useIsIOS()
    const isGetAppFb: boolean = checkIsGetAppFb(pageName);
    const isGetAppFacebook: boolean = pageName === GET_APP_FACEBOOK_URL;
    const [scrollPositionGame, setScrollPositionGame] = useState<boolean>(false);
    const [loadVideo, setLoadVideo] = useState<boolean>(false)
    const [showVideo, setShowVideo] = useState<boolean | null>(null);
    const [mute, setMute] = useState<boolean>(isMute)
    const { downloadButtonState, setDownloadButtonState } = useDownloadContext()
    const toggleOverflowProperty = () => {
        const html: HTMLHtmlElement | null = document.querySelector("html");
        if (html !== null) {
            html.style.overflowY = showVideo && !isiOSDevice ? "hidden" : "auto";
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout | undefined = undefined
        // if (isGetAppFacebook) {
        if (downloadButtonState === DownloadButtonState.DownloadClicked) {
            if (showVideo === null) { setShowVideo(true); Analytics.getInstance().logEvent(HowToVideoOpen) }
            setScrollPositionGame(true)
        } else if (downloadButtonState === DownloadButtonState.Downloading) {
            if (!showVideo && showVideo === null) {
                setShowVideo(true);
                Analytics.getInstance().logEvent(HowToVideoOpen)
            }

            timer = setTimeout(() => {
                // setIsApkDownloading(false);
                setDownloadButtonState(DownloadButtonState.DownloadAgain)
            }, APK_DOWNLOAD_AGAIN_DELAY);
        }
        /*} else {
            if (downloadButtonState === DownloadButtonState.DownloadClicked || downloadButtonState === DownloadButtonState.Downloading) {
                setShowVideo(true);
                setScrollPositionGame(true)
                timer = setTimeout(() => {
                    setIsApkDownloading(false);
                    setDownloadButtonState(DownloadButtonState.Initial)
                }, 10000);
            }
        }*/
        return () => clearTimeout(timer);
    }, [downloadButtonState]);

    const onScroll = (_event: any): void => {
        if (!isMobile) return;

        if (window.scrollY > 440) {
            setScrollPositionGame(true);
        } else if (window.scrollY <= 440) {
            setScrollPositionGame(false);
        }
    };

    // useEffect to call initial function when page is mounted on DOM
    useEffect(() => {
        setIsHowToVideoAvailable(true)
        setLoadVideo(true)
        window.addEventListener("scroll", onScroll);
        return (): void => {
            window.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
        isMobile && toggleOverflowProperty();
        if (showVideo && isIos === false) {
            const html: HTMLHtmlElement | null = document.querySelector("html");
            if (html !== null && !isGetAppFb && !isGetAppFacebook) {
                html.style.scrollBehavior = "initial";
                isMobile && window.scrollTo({ top: 450, left: 0 });
            }
            if (videoTag.current) {
                videoTag.current.currentTime = 0;
                videoTag.current.play().catch(()=>{});
            }
        } else {
            videoTag.current?.pause();
        }
        return () => {
            videoTag.current?.pause();
            const html: HTMLHtmlElement | null = document.querySelector("html");
            if (html !== null) {
                html.style.overflowY = "auto";
            }
        };
    }, [showVideo, videoTag.current]);

    const handleVideoMute = (isMute: boolean) => {
        setMute(isMute)
        Analytics.getInstance().logEvent(MuteHowToVideo, {
            mute: isMute
        })
    }

    const handleVideoPlayback = (event) => {
        event.preventDefault()
        let isVideoPlay = false
        if (videoTag?.current?.paused) {
            videoTag?.current?.play().catch(()=>{})
            isVideoPlay = true
        } else {
            videoTag?.current?.pause()
        }
        if (mute) {
            setMute(false)
        }
        Analytics.getInstance().logEvent(HowToVideoPlayback, {
            isPlay: isVideoPlay
        })
    }

    const fixedDownloadBtnProps: IFixedDownloadButtonProps = {
        type: FIXED_DOWNLOAD_BUTTON_TYPE,
        fixedDownloadButtonData: fixedDownloadButtonData,
        pageName: pageName
    };

    return blinkTextDownload ? (
        <div
            className={multipleClassName("floatingDownloadButtonContainer", styles.floatingDownloadButtonContainer)}
            style={scrollPositionGame || showVideo ? { bottom: "0px" } : { bottom: "-180px" }}
        >
            <div
                className={multipleClassName(styles.floatingDownloadButton, isGetAppFb || isGetAppFacebook ? styles.floatingDownloadButtonFB : "")}
                style={{ display: showVideo && !isiOSDevice ? "block" : "none" }}
            >
                <div style={{ height: "100%" }}>
                    <div
                        className={styles.offerPopupClose}
                        onClick={() => {
                            Analytics.getInstance().logEvent(HowToVideoClose)
                            setShowVideo(false);
                            setScrollPositionGame(false)
                        }}
                    >
                        <CustomImageComponent
                            src={
                                "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/close_offer_popup.svg"
                            }
                            width={24}
                            height={24}
                            alt="offer-close-popup"
                            layout="fixed"
                        />
                    </div>
                    {loadVideo && <>
                        <video
                            poster={props.howToInstallThumbnail}
                            src={props.howToInstallVideo}
                            className={multipleClassName(styles.videoView, isGetAppFb || isGetAppFacebook ? styles.videoViewFB : "")}
                            preload="none"
                            loop
                            ref={videoTag}
                            onVolumeChange={(event) => {
                                handleVideoMute(videoTag?.current?.muted || false)
                            }}
                            playsInline
                            muted={mute}
                            controls
                            disablePictureInPicture
                            controlsList="noplaybackrate nodownload nofullscreen"
                            onClick={(event) => {
                                handleVideoPlayback(event)
                            }}
                            onTouchEnd={(event) => {
                                handleVideoPlayback(event)
                            }}
                        />
                    </>}
                    <div
                        className={styles.videoSoundControlView}
                        onClick={() => setMute(!mute)}
                    >
                        <CustomImageComponent
                            src={
                                mute
                                    ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/mute.webp"
                                    : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/unmute.webp"
                            }
                            width={36}
                            height={36}
                            alt="offer-close-popup"
                            layout="fixed"
                        />
                    </div>
                </div>
            </div>
            {!(isGetAppFb || isGetAppFacebook) ? isiOSDevice ? (
                <p className={styles.floatingDownloadButtonText}>
                    {blinkTextDownload}
                </p>
            ) : (
                <p className={styles.floatingDownloadButtonText}>
                    {downloadButtonState === DownloadButtonState.DownloadAgain
                        ? blinkTextDownload2 || "File didn’t download?"
                        : blinkTextDownload}
                </p>
            ) : <></>}

            <FixedDownloadButtonV2 {...fixedDownloadBtnProps} />
        </div>
    ) : (
        <></>
    );
};

export default FloatingDownloadButtonComponentWithVideo;
